import Vue from "vue";
import crudMutations from "@/store/templates/crud/mutations";
import { cloneDeep, findIndex, isNil, merge, uniq } from "lodash";

export default {
  ...crudMutations,
  resetType(state) {
    state.model.type = null;
    state.model.company = null;
    state.model.companies = [];
  },
  setType(state, type) {
    state.model.type = type;
  },
  addSubFilter(state, { filter, company }) {
    state.model.filters.push({
      id: null,
      filter: filter.id,
      percentage: null
    });
    Vue.set(state.filter, filter.id, filter);
    Vue.set(state.company, company.id, company);
  },
  removeSubFilter(state, payload) {
    const subFilter = state.model.filters.find(
      filter => filter.filter === payload.filterId
    );
    const index = state.model.filters.indexOf(subFilter);
    if (index > -1) {
      state.model.filters.splice(index, 1);
    }
  },
  setSubFilterPercentage(state, payload) {
    const filter = state.model.filters.find(
      ({ filter }) => filter === payload.filterId
    );
    filter.percentage = payload.percentage;
  },
  setCompany(state, company) {
    state.model.company = company.id;
    state.isReseller = company.isReseller;
    state.companyFilterComment = company.filterComment;
  },
  addCategory(state, { category }) {
    const diyQuestion = state.alwaysVisibleQuestionIds
      .map(id => state.question[id])
      .filter(question => question.name === "default_diy")[0];

    const desiredStartQuestion = state.alwaysVisibleQuestionIds
      .map(id => state.question[id])
      .filter(question => question.name === "default_desired_start")[0];

    const isReseller = isNil(state.company[state.model.company])
      ? state.isReseller
      : state.company[state.model.company].isReseller;
    state.category[category.id] = cloneDeep(category);

    const resellerDefaultSpecifiers = [
      {
        id: null,
        options: [diyQuestion.options[0]],
        question: diyQuestion.id,
        type: isReseller ? "AT_LEAST" : "EXACT"
      }
    ];

    const nonResellerDefaultSpecifiers = [
      {
        id: null,
        options: [desiredStartQuestion.options[6]],
        question: desiredStartQuestion.id,
        type: "EXCEPT"
      },
      {
        id: null,
        options: [diyQuestion.options[0]],
        question: diyQuestion.id,
        type: isReseller ? "AT_LEAST" : "EXACT"
      }
    ];

    if (!isNil(state.model.company)) {
      state.model.filterCategories.push({
        id: null,
        category: category.id,
        specifiers: isReseller
          ? resellerDefaultSpecifiers
          : nonResellerDefaultSpecifiers
      });
    } else {
      state.model.filterCategories.push({
        id: null,
        category: category.id,
        specifiers: []
      });
    }
  },
  removeCategory(state, { categoryId }) {
    const index = state.model.filterCategories
      .map(filterCategory => filterCategory.category)
      .indexOf(categoryId);
    if (index > -1) {
      state.model.filterCategories.splice(index, 1);
    }
  },
  setLanguages(state, languages) {
    state.model.languages = languages;
  },
  setAlwaysVisibleQuestions(state, { entities, result }) {
    state.localization = merge(state.localization, entities.localization);
    state.question = merge(state.question, entities.question);
    state.questionOption = merge(state.questionOption, entities.questionOption);
    state.alwaysVisibleQuestionIds = result;
  },
  setCategoryQuestions(state, { categoryId, entities }) {
    let questionIds = [];
    entities.category[categoryId].categorySubjects
      .map(
        categorySubjectId =>
          entities.subject[entities.categorySubject[categorySubjectId].subject]
      )
      .map(subject => subject.questions)
      .forEach(subjectQuestionIds => {
        questionIds = questionIds.concat(subjectQuestionIds);
      });
    questionIds = uniq(questionIds);

    state.localization = merge(state.localization, entities.localization);
    state.question = merge(state.question, entities.question);
    state.questionOption = merge(state.questionOption, entities.questionOption);
    Vue.set(state.categoryQuestionIds, categoryId, questionIds);
  },
  addSpecifier(state, { category, question }) {
    const filterCategory = state.model.filterCategories.find(
      filterCategory => filterCategory.category === category.id
    );
    filterCategory.specifiers.push({
      id: null,
      type: "EXACT",
      question: question.rootId,
      options: []
    });
  },
  removeSpecifier(state, { category, question }) {
    const filterCategory = state.model.filterCategories.find(
      filterCategory => filterCategory.category === category.id
    );
    const index = findIndex(filterCategory.specifiers, [
      "question",
      question.rootId
    ]);
    filterCategory.specifiers.splice(index, 1);
  },
  updateSpecifier(state, { category, question, specifier }) {
    const filterCategory = state.model.filterCategories.find(
      filterCategory => filterCategory.category === category.id
    );
    const filterCategorySpecifier = filterCategory.specifiers.find(
      specifier => specifier.question === question.rootId
    );
    Vue.set(filterCategorySpecifier, "type", specifier.type);
    Vue.set(filterCategorySpecifier, "options", specifier.options);
  },
  // GEOGRAPHY
  setIsEntireCountry(state, isEntireCountry) {
    state.model.isEntireCountry = isEntireCountry;
  },
  setCountry(state, country) {
    state.model.country = country;
  },
  clearGeography(state) {
    state.model.provinces = [];
    state.model.cities = [];
    state.model.includedPolygons = [];
    state.model.excludedPolygons = [];
    state.model.includeCircles = [];
    state.model.excludedCircles = [];
  },
  setProvinces(state, provinces) {
    state.model.provinces = provinces;
  },
  setCities(state, cities) {
    state.model.cities = cities;
  },
  addPolygon(state, { coordinates, isExcluded = false }) {
    const target = isExcluded
      ? state.model.excludedPolygons
      : state.model.includedPolygons;
    target.push({
      id: null,
      coordinates,
      isExcluded
    });
  },
  addCircle(state, { center, radius, cityId = null, isExcluded = false }) {
    const target = isExcluded
      ? state.model.excludedCircles
      : state.model.includedCircles;
    target.push({
      id: null,
      center: [center.lat, center.lng],
      cityId: cityId,
      radius: radius
    });
  },
  setSelectedShape(state, shape) {
    state.selectedShape = shape;
  },
  setSelectedShapeCenter(state, center) {
    //can trigger if no shape is selected, causes an error
    if (state.selectedShape) {
      const circles = state.selectedShape.isExcluded
        ? state.model.excludedCircles
        : state.model.includedCircles;
      const shape = circles.find(
        circle =>
          circle.radius === state.selectedShape.radius &&
          circle.center[0] === state.selectedShape.center.lat &&
          circle.center[1] === state.selectedShape.center.lng
      );

      // If is required, because every time this triggers, it triggers twice. The second time nothing is found, because selection has changed.
      if (shape) {
        shape.center = [center.lat(), center.lng()];
      }
    }
  },
  setSelectedShapeRadius(state, radius) {
    const circles = state.selectedShape.isExcluded
      ? state.model.excludedCircles
      : state.model.includedCircles;

    const shape = findCircle(circles, state.selectedShape);
    // If is required, because every time this triggers, it triggers twice. The second time nothing is found, because selection has changed.
    if (shape) {
      shape.radius = radius;
    }
  },
  setSelectedShapeCoordinates(state, polygon) {
    const coordinates = polygon
      .getArray()
      .map(center => [center.lat(), center.lng()]);
    const polygons = state.selectedShape.isExcluded
      ? state.model.excludedPolygons
      : state.model.includedPolygons;

    const shape = findPolygon(polygons, state.selectedShape);

    shape.coordinates = coordinates;
  },
  removePolygon(state, { shape }) {
    const polygons = shape.isExcluded
      ? state.model.excludedPolygons
      : state.model.includedPolygons;
    const polygon = findPolygon(polygons, shape);
    const index = polygons.indexOf(polygon);

    if (shape.isExcluded) {
      state.model.excludedPolygons.splice(index, 1);
    } else {
      state.model.includedPolygons.splice(index, 1);
    }
  },
  removeCircle(state, { shape }) {
    const circles = shape.isExcluded
      ? state.model.excludedCircles
      : state.model.includedCircles;
    const circle = findCircle(circles, shape);
    const index = circles.indexOf(circle);

    if (shape.isExcluded) {
      state.model.excludedCircles.splice(index, 1);
    } else {
      state.model.includedCircles.splice(index, 1);
    }
  },
  selectCity(state, { id }) {
    const index = state.model.cities.indexOf(id);
    if (index > -1) {
      state.model.cities.splice(index, 1);
    } else {
      state.model.cities.push(id);
    }
  },
  // Meta
  setIsActive(state, isActive) {
    state.model.isActive = isActive;
  },
  setName(state, name) {
    state.model.name = name;
  },
  setApiKey(state, apiKey) {
    state.model.apiKey = apiKey;
  },
  setPriceEuro(state, priceEuro) {
    state.model.priceEuro = priceEuro;
  },
  setIsAutoclaim(state, isAutoclaim) {
    state.model.isAutoclaim = isAutoclaim;
  },
  setIsAutoDelivery(state, isAutoDelivery) {
    state.model.isAutoDelivery = isAutoDelivery;
  },
  setIsPriority(state, isPriority) {
    state.model.isPriority = isPriority;
  },
  setMaxWeeklyLeads(state, maxWeeklyLeads) {
    state.model.maxWeeklyLeads = maxWeeklyLeads;
  },
  setDeliveryMethods(state, deliveryMethods) {
    state.model.deliveryMethods = deliveryMethods;
  },
  setRemark(state, remark) {
    state.model.remark = remark;
  },
  setIsPaused(state, isPaused) {
    state.model.isPaused = isPaused;
  },
  setPausedFrom(state, pausedFrom) {
    state.model.pausedFrom = pausedFrom;
  },
  setPausedTill(state, pausedTill) {
    state.model.pausedTill = pausedTill;
  }
};

const findCircle = (circles, shape) =>
  circles.find(
    circle =>
      circle.radius === shape.radius &&
      circle.center[0] === shape.center.lat &&
      circle.center[1] === shape.center.lng
  );

const findPolygon = (polygons, shape) =>
  polygons.find(polygon =>
    polygon.coordinates.every(
      (coordinate, index) =>
        !isNil(shape.coordinates[index]) &&
        coordinate[0] === shape.coordinates[index].lat &&
        coordinate[1] === shape.coordinates[index].lng
    )
  );
